import React from "react";
import { ModuleWrapper } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";

const TwoColumnText = ({ config, leftText, rightText }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="flex flex-col md:grid grid-cols-2 px-gutter md:gap-x-20">
        <PortableTextBlock text={leftText} />
        <PortableTextBlock className="mt-10 md:mt-0" text={rightText} />
      </div>
    </ModuleWrapper>
  );
};

export default TwoColumnText;
